/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #C8453A;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1680px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 5px; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #C8453A;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #8e3028;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 10px;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 5px;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1180px; } }
  @media (min-width: 1680px) {
    .container {
      max-width: 1320px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.col-2 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-3 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.col-4 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-5 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.col-6 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-7 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.col-8 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 9; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.offset-1 {
  margin-left: 12.5%; }

.offset-2 {
  margin-left: 25%; }

.offset-3 {
  margin-left: 37.5%; }

.offset-4 {
  margin-left: 50%; }

.offset-5 {
  margin-left: 62.5%; }

.offset-6 {
  margin-left: 75%; }

.offset-7 {
  margin-left: 87.5%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-2 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-3 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-4 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-5 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-6 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-7 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-8 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 9; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 12.5%; }
  .offset-sm-2 {
    margin-left: 25%; }
  .offset-sm-3 {
    margin-left: 37.5%; }
  .offset-sm-4 {
    margin-left: 50%; }
  .offset-sm-5 {
    margin-left: 62.5%; }
  .offset-sm-6 {
    margin-left: 75%; }
  .offset-sm-7 {
    margin-left: 87.5%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-2 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-3 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-4 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-5 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-6 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-7 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-8 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 9; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 12.5%; }
  .offset-md-2 {
    margin-left: 25%; }
  .offset-md-3 {
    margin-left: 37.5%; }
  .offset-md-4 {
    margin-left: 50%; }
  .offset-md-5 {
    margin-left: 62.5%; }
  .offset-md-6 {
    margin-left: 75%; }
  .offset-md-7 {
    margin-left: 87.5%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-2 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-3 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-4 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-5 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-6 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-7 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-8 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 9; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 12.5%; }
  .offset-lg-2 {
    margin-left: 25%; }
  .offset-lg-3 {
    margin-left: 37.5%; }
  .offset-lg-4 {
    margin-left: 50%; }
  .offset-lg-5 {
    margin-left: 62.5%; }
  .offset-lg-6 {
    margin-left: 75%; }
  .offset-lg-7 {
    margin-left: 87.5%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xl-2 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-3 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xl-4 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-5 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xl-6 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-7 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xl-8 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 9; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 12.5%; }
  .offset-xl-2 {
    margin-left: 25%; }
  .offset-xl-3 {
    margin-left: 37.5%; }
  .offset-xl-4 {
    margin-left: 50%; }
  .offset-xl-5 {
    margin-left: 62.5%; }
  .offset-xl-6 {
    margin-left: 75%; }
  .offset-xl-7 {
    margin-left: 87.5%; } }

@media (min-width: 1680px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xxl-2 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-3 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xxl-4 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-5 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xxl-6 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-7 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xxl-8 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 9; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 12.5%; }
  .offset-xxl-2 {
    margin-left: 25%; }
  .offset-xxl-3 {
    margin-left: 37.5%; }
  .offset-xxl-4 {
    margin-left: 50%; }
  .offset-xxl-5 {
    margin-left: 62.5%; }
  .offset-xxl-6 {
    margin-left: 75%; }
  .offset-xxl-7 {
    margin-left: 87.5%; } }

.table {
  width: 100%;
  margin-bottom: 10px;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f0cbc8; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #e29e99; }

.table-hover .table-primary:hover {
  background-color: #ebb8b4; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #ebb8b4; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1679.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #e4a39e;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(200, 69, 58, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(200, 69, 58, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #C8453A;
  border-color: #C8453A; }
  .btn-primary:hover {
    color: #fff;
    background-color: #ac3a30;
    border-color: #a2362d; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(208, 97, 88, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #C8453A;
    border-color: #C8453A; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #a2362d;
    border-color: #98332a; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(208, 97, 88, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #C8453A;
  border-color: #C8453A; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #C8453A;
    border-color: #C8453A; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 69, 58, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #C8453A;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #C8453A;
    border-color: #C8453A; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(200, 69, 58, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #C8453A;
  text-decoration: none; }
  .btn-link:hover {
    color: #8e3028;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1680px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 5px 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #C8453A; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #C8453A;
    background-color: #C8453A; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(200, 69, 58, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #e4a39e; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #efc9c6;
    border-color: #efc9c6; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #C8453A;
  background-color: #C8453A; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(200, 69, 58, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(200, 69, 58, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(200, 69, 58, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(200, 69, 58, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #e4a39e;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(200, 69, 58, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #e4a39e;
    box-shadow: 0 0 0 0.2rem rgba(200, 69, 58, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(200, 69, 58, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(200, 69, 58, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(200, 69, 58, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #C8453A;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #efc9c6; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #C8453A;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #efc9c6; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #C8453A;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #efc9c6; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #C8453A; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 10px;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1679.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1680px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #C8453A;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #8e3028;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(200, 69, 58, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #C8453A;
  border-color: #C8453A; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #C8453A; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #a2362d; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(200, 69, 58, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #68241e;
  background-color: #f4dad8;
  border-color: #f0cbc8; }
  .alert-primary hr {
    border-top-color: #ebb8b4; }
  .alert-primary .alert-link {
    color: #401613; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #C8453A;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #C8453A;
    border-color: #C8453A; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1680px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xxl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xxl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #68241e;
  background-color: #f0cbc8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #68241e;
    background-color: #ebb8b4; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #68241e;
    border-color: #68241e; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #C8453A !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #a2362d !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #C8453A !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1680px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1680px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1680px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 10px !important; }

.mt-1,
.my-1 {
  margin-top: 10px !important; }

.mr-1,
.mx-1 {
  margin-right: 10px !important; }

.mb-1,
.my-1 {
  margin-bottom: 10px !important; }

.ml-1,
.mx-1 {
  margin-left: 10px !important; }

.m-2 {
  margin: 20px !important; }

.mt-2,
.my-2 {
  margin-top: 20px !important; }

.mr-2,
.mx-2 {
  margin-right: 20px !important; }

.mb-2,
.my-2 {
  margin-bottom: 20px !important; }

.ml-2,
.mx-2 {
  margin-left: 20px !important; }

.m-3 {
  margin: 30px !important; }

.mt-3,
.my-3 {
  margin-top: 30px !important; }

.mr-3,
.mx-3 {
  margin-right: 30px !important; }

.mb-3,
.my-3 {
  margin-bottom: 30px !important; }

.ml-3,
.mx-3 {
  margin-left: 30px !important; }

.m-4 {
  margin: 40px !important; }

.mt-4,
.my-4 {
  margin-top: 40px !important; }

.mr-4,
.mx-4 {
  margin-right: 40px !important; }

.mb-4,
.my-4 {
  margin-bottom: 40px !important; }

.ml-4,
.mx-4 {
  margin-left: 40px !important; }

.m-5 {
  margin: 50px !important; }

.mt-5,
.my-5 {
  margin-top: 50px !important; }

.mr-5,
.mx-5 {
  margin-right: 50px !important; }

.mb-5,
.my-5 {
  margin-bottom: 50px !important; }

.ml-5,
.mx-5 {
  margin-left: 50px !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 10px !important; }

.pt-1,
.py-1 {
  padding-top: 10px !important; }

.pr-1,
.px-1 {
  padding-right: 10px !important; }

.pb-1,
.py-1 {
  padding-bottom: 10px !important; }

.pl-1,
.px-1 {
  padding-left: 10px !important; }

.p-2 {
  padding: 20px !important; }

.pt-2,
.py-2 {
  padding-top: 20px !important; }

.pr-2,
.px-2 {
  padding-right: 20px !important; }

.pb-2,
.py-2 {
  padding-bottom: 20px !important; }

.pl-2,
.px-2 {
  padding-left: 20px !important; }

.p-3 {
  padding: 30px !important; }

.pt-3,
.py-3 {
  padding-top: 30px !important; }

.pr-3,
.px-3 {
  padding-right: 30px !important; }

.pb-3,
.py-3 {
  padding-bottom: 30px !important; }

.pl-3,
.px-3 {
  padding-left: 30px !important; }

.p-4 {
  padding: 40px !important; }

.pt-4,
.py-4 {
  padding-top: 40px !important; }

.pr-4,
.px-4 {
  padding-right: 40px !important; }

.pb-4,
.py-4 {
  padding-bottom: 40px !important; }

.pl-4,
.px-4 {
  padding-left: 40px !important; }

.p-5 {
  padding: 50px !important; }

.pt-5,
.py-5 {
  padding-top: 50px !important; }

.pr-5,
.px-5 {
  padding-right: 50px !important; }

.pb-5,
.py-5 {
  padding-bottom: 50px !important; }

.pl-5,
.px-5 {
  padding-left: 50px !important; }

.m-n1 {
  margin: -10px !important; }

.mt-n1,
.my-n1 {
  margin-top: -10px !important; }

.mr-n1,
.mx-n1 {
  margin-right: -10px !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -10px !important; }

.ml-n1,
.mx-n1 {
  margin-left: -10px !important; }

.m-n2 {
  margin: -20px !important; }

.mt-n2,
.my-n2 {
  margin-top: -20px !important; }

.mr-n2,
.mx-n2 {
  margin-right: -20px !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -20px !important; }

.ml-n2,
.mx-n2 {
  margin-left: -20px !important; }

.m-n3 {
  margin: -30px !important; }

.mt-n3,
.my-n3 {
  margin-top: -30px !important; }

.mr-n3,
.mx-n3 {
  margin-right: -30px !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -30px !important; }

.ml-n3,
.mx-n3 {
  margin-left: -30px !important; }

.m-n4 {
  margin: -40px !important; }

.mt-n4,
.my-n4 {
  margin-top: -40px !important; }

.mr-n4,
.mx-n4 {
  margin-right: -40px !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -40px !important; }

.ml-n4,
.mx-n4 {
  margin-left: -40px !important; }

.m-n5 {
  margin: -50px !important; }

.mt-n5,
.my-n5 {
  margin-top: -50px !important; }

.mr-n5,
.mx-n5 {
  margin-right: -50px !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -50px !important; }

.ml-n5,
.mx-n5 {
  margin-left: -50px !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 10px !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 10px !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 10px !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 10px !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 10px !important; }
  .m-sm-2 {
    margin: 20px !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 20px !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 20px !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 20px !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 20px !important; }
  .m-sm-3 {
    margin: 30px !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 30px !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 30px !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 30px !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 30px !important; }
  .m-sm-4 {
    margin: 40px !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 40px !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 40px !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 40px !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 40px !important; }
  .m-sm-5 {
    margin: 50px !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 50px !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 50px !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 50px !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 50px !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 10px !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 10px !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 10px !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 10px !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 10px !important; }
  .p-sm-2 {
    padding: 20px !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 20px !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 20px !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 20px !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 20px !important; }
  .p-sm-3 {
    padding: 30px !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 30px !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 30px !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 30px !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 30px !important; }
  .p-sm-4 {
    padding: 40px !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 40px !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 40px !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 40px !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 40px !important; }
  .p-sm-5 {
    padding: 50px !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 50px !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 50px !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 50px !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 50px !important; }
  .m-sm-n1 {
    margin: -10px !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -10px !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -10px !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -10px !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -10px !important; }
  .m-sm-n2 {
    margin: -20px !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -20px !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -20px !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -20px !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -20px !important; }
  .m-sm-n3 {
    margin: -30px !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -30px !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -30px !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -30px !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -30px !important; }
  .m-sm-n4 {
    margin: -40px !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -40px !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -40px !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -40px !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -40px !important; }
  .m-sm-n5 {
    margin: -50px !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -50px !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -50px !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -50px !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -50px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 10px !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 10px !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 10px !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 10px !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 10px !important; }
  .m-md-2 {
    margin: 20px !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 20px !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 20px !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 20px !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 20px !important; }
  .m-md-3 {
    margin: 30px !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 30px !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 30px !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 30px !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 30px !important; }
  .m-md-4 {
    margin: 40px !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 40px !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 40px !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 40px !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 40px !important; }
  .m-md-5 {
    margin: 50px !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 50px !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 50px !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 50px !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 50px !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 10px !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 10px !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 10px !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 10px !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 10px !important; }
  .p-md-2 {
    padding: 20px !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 20px !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 20px !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 20px !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 20px !important; }
  .p-md-3 {
    padding: 30px !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 30px !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 30px !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 30px !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 30px !important; }
  .p-md-4 {
    padding: 40px !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 40px !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 40px !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 40px !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 40px !important; }
  .p-md-5 {
    padding: 50px !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 50px !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 50px !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 50px !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 50px !important; }
  .m-md-n1 {
    margin: -10px !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -10px !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -10px !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -10px !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -10px !important; }
  .m-md-n2 {
    margin: -20px !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -20px !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -20px !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -20px !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -20px !important; }
  .m-md-n3 {
    margin: -30px !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -30px !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -30px !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -30px !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -30px !important; }
  .m-md-n4 {
    margin: -40px !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -40px !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -40px !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -40px !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -40px !important; }
  .m-md-n5 {
    margin: -50px !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -50px !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -50px !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -50px !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -50px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 10px !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 10px !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 10px !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 10px !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 10px !important; }
  .m-lg-2 {
    margin: 20px !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 20px !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 20px !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 20px !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 20px !important; }
  .m-lg-3 {
    margin: 30px !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 30px !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 30px !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 30px !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 30px !important; }
  .m-lg-4 {
    margin: 40px !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 40px !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 40px !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 40px !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 40px !important; }
  .m-lg-5 {
    margin: 50px !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 50px !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 50px !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 50px !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 50px !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 10px !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 10px !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 10px !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 10px !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 10px !important; }
  .p-lg-2 {
    padding: 20px !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 20px !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 20px !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 20px !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 20px !important; }
  .p-lg-3 {
    padding: 30px !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 30px !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 30px !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 30px !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 30px !important; }
  .p-lg-4 {
    padding: 40px !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 40px !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 40px !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 40px !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 40px !important; }
  .p-lg-5 {
    padding: 50px !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 50px !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 50px !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 50px !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 50px !important; }
  .m-lg-n1 {
    margin: -10px !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -10px !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -10px !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -10px !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -10px !important; }
  .m-lg-n2 {
    margin: -20px !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -20px !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -20px !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -20px !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -20px !important; }
  .m-lg-n3 {
    margin: -30px !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -30px !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -30px !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -30px !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -30px !important; }
  .m-lg-n4 {
    margin: -40px !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -40px !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -40px !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -40px !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -40px !important; }
  .m-lg-n5 {
    margin: -50px !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -50px !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -50px !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -50px !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -50px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 10px !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 10px !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 10px !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 10px !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 10px !important; }
  .m-xl-2 {
    margin: 20px !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 20px !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 20px !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 20px !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 20px !important; }
  .m-xl-3 {
    margin: 30px !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 30px !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 30px !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 30px !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 30px !important; }
  .m-xl-4 {
    margin: 40px !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 40px !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 40px !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 40px !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 40px !important; }
  .m-xl-5 {
    margin: 50px !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 50px !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 50px !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 50px !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 50px !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 10px !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 10px !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 10px !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 10px !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 10px !important; }
  .p-xl-2 {
    padding: 20px !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 20px !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 20px !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 20px !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 20px !important; }
  .p-xl-3 {
    padding: 30px !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 30px !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 30px !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 30px !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 30px !important; }
  .p-xl-4 {
    padding: 40px !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 40px !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 40px !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 40px !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 40px !important; }
  .p-xl-5 {
    padding: 50px !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 50px !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 50px !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 50px !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 50px !important; }
  .m-xl-n1 {
    margin: -10px !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -10px !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -10px !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -10px !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -10px !important; }
  .m-xl-n2 {
    margin: -20px !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -20px !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -20px !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -20px !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -20px !important; }
  .m-xl-n3 {
    margin: -30px !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -30px !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -30px !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -30px !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -30px !important; }
  .m-xl-n4 {
    margin: -40px !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -40px !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -40px !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -40px !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -40px !important; }
  .m-xl-n5 {
    margin: -50px !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -50px !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -50px !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -50px !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -50px !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1680px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 10px !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 10px !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 10px !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 10px !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 10px !important; }
  .m-xxl-2 {
    margin: 20px !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 20px !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 20px !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 20px !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 20px !important; }
  .m-xxl-3 {
    margin: 30px !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 30px !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 30px !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 30px !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 30px !important; }
  .m-xxl-4 {
    margin: 40px !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 40px !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 40px !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 40px !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 40px !important; }
  .m-xxl-5 {
    margin: 50px !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 50px !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 50px !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 50px !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 50px !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 10px !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 10px !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 10px !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 10px !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 10px !important; }
  .p-xxl-2 {
    padding: 20px !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 20px !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 20px !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 20px !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 20px !important; }
  .p-xxl-3 {
    padding: 30px !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 30px !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 30px !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 30px !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 30px !important; }
  .p-xxl-4 {
    padding: 40px !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 40px !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 40px !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 40px !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 40px !important; }
  .p-xxl-5 {
    padding: 50px !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 50px !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 50px !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 50px !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 50px !important; }
  .m-xxl-n1 {
    margin: -10px !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -10px !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -10px !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -10px !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -10px !important; }
  .m-xxl-n2 {
    margin: -20px !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -20px !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -20px !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -20px !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -20px !important; }
  .m-xxl-n3 {
    margin: -30px !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -30px !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -30px !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -30px !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -30px !important; }
  .m-xxl-n4 {
    margin: -40px !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -40px !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -40px !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -40px !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -40px !important; }
  .m-xxl-n5 {
    margin: -50px !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -50px !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -50px !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -50px !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -50px !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1680px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #C8453A !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #8e3028 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.js-preloader {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%); }

.preload {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #fff;
  z-index: 999;
  position: absolute;
  bottom: 0; }

.loader {
  width: 80px;
  height: 80px;
  position: relative; }
  .loader__spinner {
    position: absolute;
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    box-shadow: inset 0 calc((70px/10)*-1) 0 0 #3998e8, inset calc((70px/10)*-1) calc(70px/10) 0 0 rgba(57, 152, 232, 0.6), inset calc(70px/10) calc(70px/10) 0 0 rgba(57, 152, 232, 0.3);
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    margin: 0 auto;
    left: 50%;
    margin-left: 35px; }
  .loader__text {
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif;
    color: #000; }

input::-webkit-input-placeholder {
  color: #C8453A !important; }

input::-moz-placeholder {
  color: #C8453A !important; }

input:-ms-input-placeholder {
  color: #C8453A !important; }

body {
  font-family: Poppins, sans-serif;
  line-height: 1.4; }
  @media (max-width: 991.98px) {
    body {
      font-size: 14px; } }

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none; }

p {
  margin-bottom: 0; }

.color-brand {
  color: #C8453A; }

.header {
  height: 90vh;
  background: url(public/header-bg.png) no-repeat bottom right #eff5f9; }
  .header .h1 {
    margin-top: 14vh; }
  @media (max-width: 991.98px) {
    .header {
      height: auto;
      padding-bottom: 60px;
      -webkit-background-size: 50vw;
      background-size: 50vw; } }
  @media (max-width: 767.98px) {
    .header {
      padding-bottom: 35px; } }

a {
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

.color-primary {
  color: #C8453A; }

.hamburger-menu {
  margin: 0;
  cursor: pointer;
  padding: 17px 14.5px;
  width: 55px;
  z-index: 801; }
  .hamburger-menu span {
    display: block;
    width: 26px;
    height: 2px;
    background-color: #C8453A;
    transition: all .35s ease; }
  .hamburger-menu-item-second {
    margin: 6px 0; }
  .hamburger-menu.active .hamburger-menu-item-first {
    transform: translateY(8px) translateX(0) rotate(45deg); }
  .hamburger-menu.active .hamburger-menu-item-second {
    opacity: 0; }
  .hamburger-menu.active .hamburger-menu-item-third {
    transform: translateY(-8px) translateX(0) rotate(-45deg); }

@media (max-width: 991.98px) {
  .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.9);
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    z-index: 800;
    display: none; }
    .menu.active {
      display: flex; } }

.menu li {
  line-height: 1;
  font-size: 18px; }

.menu-link {
  display: inline-block;
  transition: all .3s ease;
  color: #000000;
  padding: 10px 24px;
  font-weight: 700; }
  @media (max-width: 991.98px) {
    .menu-link {
      padding: 20px; } }
  .menu-link:hover {
    color: #C8453A; }

.submenu {
  position: absolute;
  top: 200%;
  left: 0;
  background: #eff5f9;
  transition: all .5s ease;
  opacity: 0;
  visibility: hidden;
  z-index: 99; }
  @media (max-width: 991.98px) {
    .submenu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      z-index: 801;
      transform: scale(0); }
      .submenu.active {
        transform: scale(1);
        visibility: visible;
        opacity: 1; }
      .submenu li {
        text-align: center; } }
  .submenu-wrap {
    position: relative; }
    @media (min-width: 992px) {
      .submenu-wrap:hover .submenu {
        top: 100%;
        visibility: visible;
        opacity: 1; } }
    .submenu-wrap > span {
      position: relative;
      cursor: pointer; }
      @media (min-width: 992px) {
        .submenu-wrap > span {
          padding-right: 40px; } }
      .submenu-wrap > span:after {
        content: '';
        height: 8px;
        width: 13px;
        background: url(public/angle.png) no-repeat;
        display: inline-block;
        position: absolute;
        top: calc(50% - 4px);
        right: 18px; }
        @media (max-width: 991.98px) {
          .submenu-wrap > span:after {
            right: auto;
            left: 100%;
            transform: rotate(-90deg); } }
  .submenu-link {
    display: inline-block;
    padding: 20px 24px;
    font-weight: 700;
    color: #000000;
    white-space: nowrap; }

.menu-back {
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px 20px 20px 30px;
  cursor: pointer;
  font-weight: 600; }

.button {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background-color: #C8453A;
  padding: 13px 30px;
  transition: all .3s ease;
  border: 2px solid #C8453A;
  display: inline-block; }
  .button-sm {
    padding: 8px 15px;
    font-size: 14px;
    text-transform: capitalize; }
  @media (max-width: 767.98px) {
    .button {
      font-size: 12px;
      padding: 10px 15px; } }
  .button:hover {
    background-color: transparent;
    color: #C8453A; }
  .button--no-transparent:hover {
    background-color: #ad3d32;
    color: #fff; }
  .button:disabled {
    opacity: .5; }
    .button:disabled:hover {
      color: #ffffff;
      background-color: #C8453A;
      box-shadow: 0 0 24px rgba(200, 69, 58, 0.16); }
  .button-invert {
    border: 2px solid #C8453A;
    background-color: transparent;
    transition: all .3s ease;
    font-weight: 700;
    border-radius: 8px;
    padding: 8px 12px;
    display: inline-block; }
    @media (max-width: 991.98px) {
      .button-invert {
        padding: 20px 40px;
        margin-top: 20px; } }
    .button-invert:hover {
      background: #C8453A;
      color: #ffffff; }
  .button-cart:hover {
    background: #C8453A;
    color: #ffffff; }

@media (max-width: 767.98px) {
  .container {
    padding-left: 30px;
    padding-right: 30px; } }

.h1 {
  color: #000000;
  font-size: 56px;
  font-weight: 700;
  letter-spacing: 1.4px;
  line-height: 1.18;
  margin-top: 225px; }
  @media (max-width: 1199.98px) {
    .h1 {
      margin-top: 150px;
      font-size: 45px; } }
  @media (max-width: 991.98px) {
    .h1 {
      font-size: 40px; } }
  @media (max-width: 767.98px) {
    .h1 {
      font-size: 30px;
      letter-spacing: 0.75px;
      line-height: 1.2;
      margin-top: 85px; } }
  .h1 span {
    position: relative;
    z-index: 2; }
  .h1-img {
    left: 13px;
    top: 0;
    transform: translateY(-67%);
    z-index: 1;
    opacity: .3; }
    @media (max-width: 991.98px) {
      .h1-img {
        width: 100px;
        height: auto; } }
  .h1-after {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.6px;
    line-height: 1.25;
    margin-bottom: 70px; }
    @media (max-width: 767.98px) {
      .h1-after {
        font-size: 12px;
        margin-bottom: 15px; } }

.h2 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.25;
  color: #000000; }
  @media (max-width: 1199.98px) {
    .h2 {
      font-size: 30px; } }
  @media (max-width: 767.98px) {
    .h2 {
      font-size: 21px; } }

.text {
  font-weight: 300;
  letter-spacing: 0.4px; }

.section {
  padding: 72px 0; }
  @media (max-width: 1199.98px) {
    .section {
      padding: 60px 0; } }
  @media (max-width: 991.98px) {
    .section {
      padding: 34px 0; } }
  .section .text {
    max-width: 470px; }

.timeline {
  position: relative;
  padding: 110px 0; }
  .timeline .text {
    font-size: 18px; }
  .timeline li {
    font-size: 18px; }
  @media (max-width: 1199.98px) {
    .timeline {
      padding: 60px 0; } }
  @media (max-width: 991.98px) {
    .timeline {
      padding: 0; }
      .timeline img {
        display: block;
        margin: 0 auto; } }
  .timeline:after {
    content: '';
    display: inline-block;
    height: 100%;
    width: 2px;
    background: #e1e4eb;
    position: absolute;
    top: 0;
    left: calc(50% - 1px); }
    @media (max-width: 991.98px) {
      .timeline:after {
        display: none; } }
  .timeline:before {
    content: '';
    height: 92px;
    width: 92px;
    background: url(public/check.png) no-repeat center center/contain;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%); }
    @media (max-width: 767.98px) {
      .timeline:before {
        width: 50px;
        height: 50px;
        bottom: -30px; } }
  .timeline .row {
    margin-bottom: 250px;
    justify-content: space-between;
    align-items: center;
    position: relative; }
    @media (max-width: 1199.98px) {
      .timeline .row {
        margin-bottom: 110px; } }
    @media (max-width: 767.98px) {
      .timeline .row {
        margin-bottom: 45px; } }
    .timeline .row:before {
      content: '';
      width: 114px;
      height: 114px;
      border: 8px solid #dcdde6;
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      color: #dcdde6;
      font-size: 40px;
      font-weight: 700;
      letter-spacing: 1px;
      text-align: center;
      line-height: 100px; }
      @media (max-width: 991.98px) {
        .timeline .row:before {
          top: 0;
          left: 15px;
          transform: none; } }
      @media (max-width: 767.98px) {
        .timeline .row:before {
          width: 50px;
          height: 50px;
          border-width: 4px;
          font-size: 24px;
          line-height: 45px; } }
    .timeline .row:nth-child(1):before {
      content: '1'; }
    .timeline .row:nth-child(2):before {
      content: '2'; }
    .timeline .row:nth-child(3):before {
      content: '3'; }
    @media (min-width: 768px) {
      .timeline .row:first-child, .timeline .row:last-child {
        flex-direction: row-reverse; } }

.questions {
  padding: 80px 0;
  height: 100%; }
  @media (max-width: 1679.98px) {
    .questions {
      padding: 60px 0; } }
  @media (max-width: 991.98px) {
    .questions {
      padding: 40px 0; } }
  @media (max-width: 767.98px) {
    .questions {
      padding: 0; } }
  .questions .container {
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.16);
    background: #fff;
    position: relative;
    overflow: scroll;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start; }
    .questions .container form {
      flex-grow: 1; }
  .questions-progress {
    background-color: #D87D75;
    height: 18px;
    position: relative;
    z-index: 2; }
    .questions-progress-value {
      height: 18px;
      background: #C8453A;
      min-width: 115px;
      display: inline-block;
      -webkit-border-radius: 0 10px 10px 0;
      -moz-border-radius: 0 10px 10px 0;
      border-radius: 0 10px 10px 0; }
  .questions-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    opacity: .3; }
    @media (max-width: 991.98px) {
      .questions-bg {
        width: 50vw;
        height: auto; } }

.h3 {
  color: #000000;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.25; }
  @media (max-width: 1199.98px) {
    .h3 {
      font-size: 30px; } }
  @media (max-width: 767.98px) {
    .h3 {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.35px;
      line-height: 21px; } }

.next-screen {
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.16);
  position: relative;
  overflow: hidden;
  margin: 300px 0; }
  @media (max-width: 767.98px) {
    .next-screen {
      margin: 110px 0; } }

.question {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: .3s all ease;
  min-height: 100%;
  overflow: hidden;
  padding: 0 40px 3vh;
  position: relative;
  z-index: 2; }
  .question-index {
    min-height: 850px;
    padding-top: 70px; }
    @media (max-width: 767.98px) {
      .question-index h3 {
        margin-bottom: 50px; } }
  .question .h2 {
    line-height: 1; }
    @media (max-width: 767.98px) {
      .question .h2 {
        font-size: 21px; } }
  @media (max-width: 1199.98px) {
    .question {
      min-height: 600px;
      padding: 40px; } }
  @media (max-width: 767.98px) {
    .question {
      padding: 30px 10px; } }
  .question[data-current=true] {
    display: flex;
    visibility: visible;
    opacity: 1; }
  .question .h2 {
    font-size: 56px;
    align-self: flex-start; }
    @media (max-width: 1199.98px) {
      .question .h2 {
        font-size: 40px; } }
    @media (max-width: 767.98px) {
      .question .h2 {
        font-size: 21px; } }
  .question-buttons {
    align-self: center; }

svg path {
  transition: .3s all ease; }

.active-radio, .radio-label-block:hover, .radio:checked + .radio-label .radio-label-block {
  border: 4px solid #C8453A; }
  .active-radio svg path, .radio-label-block:hover svg path, .radio:checked + .radio-label .radio-label-block svg path {
    stroke: #C8453A; }

@media (min-width: 768px) {
  .radio-label {
    margin: 0 35px; } }

.radio-label-block {
  width: 264px;
  height: 264px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.24);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 4px solid transparent;
  transition: all .3s ease; }
  @media (max-width: 1199.98px) {
    .radio-label-block {
      height: 180px;
      width: 180px; }
      .radio-label-block svg {
        height: 100px;
        width: auto; } }
  @media (max-width: 767.98px) {
    .radio-label-block {
      height: 125px;
      width: 125px; } }

.radio-label-text {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.6px;
  text-align: center;
  display: block;
  margin-top: 20px; }
  @media (max-width: 767.98px) {
    .radio-label-text {
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 20px; } }

.radio {
  display: none; }

.form-control {
  display: block;
  width: 100%;
  max-width: 644px;
  border-radius: 10px;
  border: 2px solid #C8453A;
  background-color: #eaf4fe;
  line-height: 1;
  padding: 15px 35px;
  color: #C8453A;
  height: auto; }
  @media (max-width: 1199.98px) {
    .form-control {
      max-width: 400px; } }
  @media (max-width: 767.98px) {
    .form-control {
      padding: 10px 15px; } }
  .form-control:focus {
    color: #C8453A; }

.input-hint {
  font-size: 24px;
  opacity: .45;
  margin-top: 10px; }
  @media (max-width: 1199.98px) {
    .input-hint {
      font-size: 20px; } }

.questions-line {
  justify-content: center;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 25px;
  display: none;
  transition: .3s all ease;
  position: relative;
  padding: 0 10px;
  display: flex;
  max-width: 910px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 767.98px) {
    .questions-line {
      align-items: center; } }
  .questions-line[data-visible=true] {
    display: flex; }
  .questions-line .question-logo {
    position: absolute;
    width: 90px;
    right: 10px;
    top: 10px; }
    .questions-line .question-logo img {
      width: 100%; }

.question-dot {
  color: #9fb1bf;
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  height: 40px; }
  @media (max-width: 767.98px) {
    .question-dot {
      height: auto; } }
  .question-dot[data-state=current] .dot {
    background: #C8453A;
    color: #C8453A; }
    @media (max-width: 767.98px) {
      .question-dot[data-state=current] .dot {
        width: 16px;
        height: 16px;
        border: 2px solid #ffffff;
        background-color: #C8453A; } }
  .question-dot[data-state=past] .dot {
    background: #C8453A; }
    @media (max-width: 767.98px) {
      .question-dot[data-state=past] .dot {
        width: 16px;
        height: 16px;
        border: 1px solid #ffffff;
        background-color: #C8453A; } }
  .question-dot[data-state=past] + .divider {
    background-color: #C8453A; }
    @media (max-width: 767.98px) {
      .question-dot[data-state=past] + .divider {
        height: 10px; } }
  .question-dot .dot {
    width: 6px;
    height: 6px;
    background-color: #9fb1bf;
    display: block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: relative; }
    .question-dot .dot-title {
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap; }
      @media (max-width: 767.98px) {
        .question-dot .dot-title {
          display: none; } }

.divider {
  width: 130px;
  height: 3px;
  background-color: #9fb1bf;
  width: auto;
  flex-grow: 1;
  opacity: .5; }
  @media (min-width: 768px) {
    .divider {
      margin: 1px 2px 0; } }
  @media (max-width: 1679.98px) {
    .divider {
      width: 100px;
      width: auto;
      flex-grow: 1; } }
  @media (max-width: 991.98px) {
    .divider {
      width: 67px;
      width: auto;
      flex-grow: 1; } }

.select2-container--default .select2-selection--single {
  border: 2px solid #C8453A;
  background-color: #eaf4fe;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  height: auto;
  border-radius: 4px !important; }

.select2-selection__rendered {
  color: #C8453A !important;
  font-size: 24px;
  font-weight: 400;
  line-height: 1 !important; }
  @media (max-width: 767.98px) {
    .select2-selection__rendered {
      font-size: 12px; } }

.select2-selection__arrow {
  width: 26px !important;
  height: 16px !important;
  background: url(public/select_angle.png);
  right: 30px !important;
  top: 50% !important;
  transform: translateY(-50%);
  -webkit-background-size: contain;
  background-size: contain; }
  @media (max-width: 767.98px) {
    .select2-selection__arrow {
      width: 14px !important;
      height: 9px !important; } }
  .select2-selection__arrow b {
    display: none; }

.select2-container--default .select2-results__option {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  color: #C8453A !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 1 !important;
  background: #ffffff !important; }
  @media (max-width: 767.98px) {
    .select2-container--default .select2-results__option {
      font-size: 12px; } }

.select2-container--default .select2-results__option--highlighted {
  background-color: #f1f9ff !important; }

.select2-container--default .select2-results__option--highlighted[aria-selected="true"] {
  background-color: #f1f9ff !important; }

.select2-dropdown {
  overflow: hidden;
  border-width: 2px;
  border-color: #C8453A; }

.select2-results__option + .select2-results__option {
  border-top: 2px solid #C8453A !important; }

.chart-content {
  width: 200px;
  height: 200px; }
  @media (max-width: 1199.98px) {
    .chart-content {
      width: 150px;
      height: 150px; } }
  @media (max-width: 767.98px) {
    .chart-content {
      width: 23vw;
      height: 23vw; } }

.chart p {
  color: #000000;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.6px;
  line-height: 30px;
  margin-bottom: 20px; }
  @media (max-width: 767.98px) {
    .chart p {
      font-size: 16px;
      margin-bottom: 5px;
      line-height: 20px; } }

.circle {
  position: relative; }
  .circle span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000000;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.6px; }
    @media (max-width: 767.98px) {
      .circle span {
        font-size: 22px; } }

.total-calories {
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.6px;
  line-height: 30px; }
  .total-calories span {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 30px; }
    @media (max-width: 767.98px) {
      .total-calories span {
        display: block;
        color: #ffffff !important;
        font-size: 24px; } }
  @media (max-width: 767.98px) {
    .total-calories {
      font-size: 16px;
      color: #ffffff;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      background-color: #C8453A;
      text-align: center;
      padding: 10px 5px; } }

.meal {
  width: 177px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  padding: 20px 0 30px;
  text-align: center; }
  @media (max-width: 1199.98px) {
    .meal {
      padding: 15px 10px; } }
  @media (max-width: 767.98px) {
    .meal {
      display: flex;
      width: 100%;
      padding: 5px 10px;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 15px;
      border-radius: 4px; } }
  .meal p {
    margin-top: 10px; }
    @media (max-width: 1199.98px) {
      .meal p {
        margin-top: 5px; } }
    @media (max-width: 767.98px) {
      .meal p {
        margin-top: 0;
        font-size: 8px; } }
  .meal-title {
    color: #C8453A;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 30px;
    margin-top: 0 !important; }
    @media (max-width: 1199.98px) {
      .meal-title {
        line-height: 1.4;
        font-size: 18px; } }
    @media (max-width: 767.98px) {
      .meal-title {
        line-height: 1.4;
        font-size: 16px !important; } }

.result-container {
  position: relative;
  padding-bottom: 90px; }

.meals {
  margin-top: 70px; }
  @media (max-width: 1199.98px) {
    .meals {
      margin-top: 30px; } }
  @media (max-width: 767.98px) {
    .meals {
      margin-top: 20px; } }

.result-overlay {
  background: #c8453a;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  display: none; }
  .result-overlay__title {
    font-weight: 900;
    font-size: 60px;
    color: #fff; }
    @media (max-width: 991.98px) {
      .result-overlay__title {
        font-size: 50px; } }
    @media (max-width: 767.98px) {
      .result-overlay__title {
        font-size: 28px; } }
  .result-overlay__item {
    position: absolute;
    top: 50%;
    left: 50%;
    transition: all 1.2s ease;
    transform: translate(-50%, 0%);
    color: #fff;
    opacity: 0;
    height: 200px;
    width: 700px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 767.98px) {
      .result-overlay__item {
        max-width: 96%; } }
    .result-overlay__item.is-active {
      opacity: 1;
      transform: translate(-50%, -50%);
      color: #fff; }
    .result-overlay__item.is-visible {
      transform: translate(-50%, -150%) scale(0.7);
      opacity: 0.6; }
      @media (max-width: 767.98px) {
        .result-overlay__item.is-visible {
          transform: translate(-50%, -100%) scale(0.7); } }
    .result-overlay__item.fade {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0; }
      @media (max-width: 767.98px) {
        .result-overlay__item.fade {
          transform: translate(-50%, -50%) scale(1);
          opacity: 0; } }
    .result-overlay__item.is-hidden {
      display: none; }
  .result-overlay__text {
    background: #fff;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.6px;
    display: block;
    width: 840px;
    height: auto;
    border-radius: 8px;
    text-align: center;
    justify-content: center;
    padding: 12px;
    background: #c8453a;
    border: 8px solid #fff;
    color: #fff;
    font-size: 36px;
    max-width: 96%; }
    .result-overlay__text span {
      font-size: 36px;
      font-weight: 700;
      letter-spacing: 0.8px;
      color: #ffffff !important; }
      @media (max-width: 767.98px) {
        .result-overlay__text span {
          display: block;
          color: #ffffff !important;
          font-size: 24px; } }
    .result-overlay__text.is-visible {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0 !important; }
    @media (max-width: 767.98px) {
      .result-overlay__text {
        font-size: 16px;
        color: #ffffff;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        background-color: #C8453A;
        text-align: center;
        padding: 10px 5px;
        border: 5px solid #fff; } }

@media (max-width: 767.98px) {
  .question-buttons .button {
    background-color: transparent;
    color: #C8453A;
    padding: 10px 50px; } }

.select2-container--open .select2-dropdown--below {
  border-radius: 4px !important; }

.select2-selection__placeholder {
  color: rgba(200, 69, 58, 0.6) !important; }

.select2-container--open .select2-dropdown {
  top: calc(100% + 55px); }

.select2-container--default .select2-results > .select2-results__options {
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-top: 2px solid #C8453A; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: none; }

.hint {
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 10px;
  cursor: pointer; }

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background: rgba(0, 0, 0, 0.7);
  transform: scale(0);
  opacity: 0;
  visibility: hidden;
  transition: .3s all ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 900; }
  .popup[data-visible=true] {
    transform: scale(1);
    opacity: 1;
    visibility: visible; }
  .popup-inner {
    background: #ffffff;
    padding: 30px 38px;
    position: relative;
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 645px;
    max-height: 100vh;
    overflow: auto;
    width: 98%;
    border-radius: 8px; }
    @media (max-width: 767.98px) {
      .popup-inner {
        padding: 35px 15px; } }
  .popup-title {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    text-align: center; }
    @media (max-width: 767.98px) {
      .popup-title {
        font-size: 18px; } }

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer; }

.activity-item {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  text-align: center;
  margin-top: 16px;
  padding: 8px; }
  .activity-item p {
    font-weight: 600; }
  .activity-item span {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.3px; }

.activity-popup {
  width: 100%;
  max-width: 645px; }

.last-step {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-end; }
  @media (max-width: 1199.98px) {
    .last-step {
      flex-wrap: wrap; } }

.build {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  background-color: #ffffff;
  padding: 30px 30px 30px;
  max-width: 430px;
  margin: 0 auto; }
  .build img {
    width: 100%; }
  @media (max-width: 1679.98px) {
    .build {
      width: 340px;
      margin: 0 auto; } }
  @media (max-width: 767.98px) {
    .build {
      flex-basis: 100%;
      max-width: 340px;
      margin: auto; } }
  .build .js-popup-open {
    transition: .3s all ease; }
    .build .js-popup-open:hover {
      transform: scale(1.05);
      z-index: 4; }
  .build .button {
    position: absolute;
    bottom: 0;
    left: 40px;
    transform: translateY(50%); }
    .build .button:hover {
      transform: translateY(50%); }

.build-dot {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 23px;
  height: 23px;
  background-color: #9fb1bf;
  z-index: 3;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%; }
  .build-dot:before {
    content: '';
    position: absolute;
    top: -9px;
    left: -9px;
    width: 41px;
    height: 41px;
    opacity: 0.45;
    background-color: #9fb1bf;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%; }

.item img {
  position: relative;
  left: 50%;
  transform: translateX(-50%); }

.c2 {
  position: relative;
  width: 28.1%; }
  .c2 .build-dot {
    left: 40%;
    bottom: -5px; }

.pa {
  position: relative;
  z-index: 2;
  width: 19.7%; }
  .pa .build-dot {
    left: 40%;
    bottom: -15px; }

.v1 {
  position: relative; }
  .v1 .build-dot {
    left: 40%;
    bottom: -5px; }

.pack {
  width: 33.8%;
  position: relative; }
  .pack img {
    transform: translateX(-40%); }
  .pack .build-dot {
    left: 20%;
    bottom: 20px; }

.v1 {
  width: 16%; }

.js-popup-open {
  cursor: pointer; }

.vertical-item {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background-color: #ffffff;
  padding: 10px 35px 10px 150px;
  text-align: center;
  cursor: pointer;
  transition: .3s all ease;
  position: relative;
  margin: 0 1% 30px;
  flex-basis: 48%;
  flex-grow: 1;
  font-size: 20px;
  text-align: left; }
  @media (max-width: 991.98px) {
    .vertical-item {
      padding-left: 100px; } }
  @media (max-width: 767.98px) {
    .vertical-item {
      flex-wrap: wrap; } }
  .vertical-item-price {
    text-align: center;
    font-weight: 600; }
    @media (max-width: 767.98px) {
      .vertical-item-price {
        margin-top: 10px; } }
    .vertical-item-price span {
      padding: 0 5px;
      text-transform: uppercase;
      display: inline-block;
      margin-bottom: 4px;
      font-size: 16px; }
      .vertical-item-price span:nth-child(1) {
        text-decoration: line-through; }
      .vertical-item-price span:nth-child(2) {
        border-right: 2px solid #000; }
    .vertical-item-price .button {
      width: 100%; }
  .vertical-item:hover {
    transform: scale(1.05); }
  .vertical-item span {
    font-weight: 600; }
  .vertical-item img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .vertical-items {
    min-width: 340px;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 30px 0px;
    margin-bottom: 0px;
    display: flex; }
    @media (max-width: 991.98px) {
      .vertical-items {
        min-width: auto;
        margin: 0; } }
    @media (max-width: 767.98px) {
      .vertical-items {
        margin-top: 60px;
        width: 100%;
        flex-basis: 100%; } }

.vi-c2 img {
  left: 40px; }
  @media (max-width: 991.98px) {
    .vi-c2 img {
      left: 17px; } }

.vi-pa img {
  left: 55px; }
  @media (max-width: 991.98px) {
    .vi-pa img {
      left: 25px; } }

.vi-v1 img {
  left: 60px; }
  @media (max-width: 991.98px) {
    .vi-v1 img {
      left: 30px; } }

.vi-m6 img {
  left: 66px;
  width: 57px; }
  @media (max-width: 991.98px) {
    .vi-m6 img {
      left: 30px; } }

.vi-n4 img {
  left: 66px;
  width: 52px; }
  @media (max-width: 991.98px) {
    .vi-n4 img {
      left: 30px; } }

.vi-s9 img {
  left: 66px;
  width: 52px; }
  @media (max-width: 991.98px) {
    .vi-s9 img {
      left: 30px; } }

.vi-nad3 img {
  left: 66px;
  width: 52px; }
  @media (max-width: 991.98px) {
    .vi-nad3 img {
      left: 30px; } }

.bundle__right {
  flex-basis: calc(100% - 430px); }
  @media (max-width: 1679.98px) {
    .bundle__right {
      flex-basis: calc(100% - 340px); } }
  @media (max-width: 1199.98px) {
    .bundle__right {
      max-width: none;
      flex-basis: 100%;
      margin-top: 60px; } }

.bundle-text {
  align-self: flex-start !important;
  flex-basis: 100%;
  max-width: none;
  padding: 0 30px;
  font-size: 18px; }

.product-info {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  padding: 70px 30px 20px;
  position: relative;
  text-align: center;
  margin-top: 40px; }
  @media (max-width: 767.98px) {
    .product-info {
      padding: 50px 15px 15px; } }
  .product-info-open {
    color: #C8453A;
    font-size: 18px;
    font-weight: 700;
    border: none;
    background-color: transparent;
    margin-top: 70px; }
  .product-info .button {
    position: absolute;
    top: 0;
    right: 40px;
    transform: translateY(-50%); }
    .product-info .button--static {
      position: static;
      transform: translateY(0%);
      margin-top: 30px;
      margin-bottom: 20px; }

.dismiss {
  opacity: 0.45;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  margin: 8px 0 20px;
  cursor: pointer; }

.calculator {
  height: 100vh; }
  @media (max-width: 1199.98px) {
    .calculator {
      height: auto; } }

.bundle {
  display: none;
  width: 100%;
  padding-bottom: 90px;
  margin-top: 30px; }
  .bundle__pack-item {
    position: relative;
    width: 25%; }
    .bundle__pack-item .build-dot {
      left: 40%;
      bottom: -5px; }
    .bundle__pack-item--n4 {
      z-index: 1; }
      .bundle__pack-item--n4 img {
        width: 87px; }
    .bundle__pack-item--m6 {
      z-index: 2;
      transform: translateY(5%); }
      .bundle__pack-item--m6 img {
        width: 87px; }
    .bundle__pack-item--v1 {
      z-index: 1;
      transform: translateX(5%); }
      .bundle__pack-item--v1 img {
        width: 83px; }
    .bundle__pack-item--n4 {
      z-index: 1;
      transform: translateX(5%); }
      .bundle__pack-item--n4 img {
        width: 83px; }
    .bundle__pack-item--s9 {
      z-index: 2;
      transform: translateX(5%); }
      .bundle__pack-item--s9 img {
        width: 87px; }
    .bundle__pack-item--nad3 {
      z-index: 2;
      transform: translateY(0px); }
      .bundle__pack-item--nad3 img {
        width: 93px; }

.popup-select__dropdown.select2-container--open .select2-dropdown {
  top: 100%; }

.popup-select__dropdown.select2-container--default .select2-results > .select2-results__options {
  max-height: 260px; }

.supplement__title {
  font-size: 24px;
  font-weight: bold; }
  .supplement__title strong {
    font-weight: bold; }

.supplement__row {
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  padding: 10px 15px 10px;
  position: relative;
  text-align: center;
  margin-bottom: 10px;
  min-height: 62px; }
  .supplement__row--header {
    box-shadow: none;
    font-size: 10px;
    padding: 0 15px;
    margin-bottom: 5px;
    min-height: 0px; }
    .supplement__row--header .supplement__col {
      font-weight: bold !important;
      font-size: 10px;
      align-items: center;
      align-content: center;
      display: flex; }

.supplement__col {
  font-size: 15px;
  font-weight: bold;
  align-items: center;
  align-content: center;
  display: flex;
  flex-wrap: wrap; }
  .supplement__col.pl-2 {
    font-weight: normal; }
  .supplement__col .bolder {
    font-weight: 900; }
  .supplement__col small {
    font-size: 11px;
    display: block;
    font-weight: bold;
    flex-basis: 100%; }
    @media (max-width: 767.98px) {
      .supplement__col small {
        font-size: 9px; } }
  .supplement__col:first-child {
    flex-grow: 1;
    text-align: left;
    padding-right: 10px; }
  .supplement__col:nth-child(2) {
    flex-basis: 75px;
    flex-shrink: 0;
    flex-grow: 0;
    padding-right: 10px;
    border-right: 1px solid #eee;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-weight: normal; }
  .supplement__col:last-child {
    flex-basis: 40px;
    text-align: center;
    padding-left: 10px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-weight: normal; }

.supplement__footer {
  margin: 0px 0 30px;
  font-size: 11px;
  padding: 0 15px; }
  @media (max-width: 767.98px) {
    .supplement__footer {
      font-size: 9px; } }

.selection__table {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 800px;
  max-width: 100%;
  margin: 20px auto 0; }

.selection__col {
  flex-basis: 50%;
  font-weight: bold;
  font-size: 18px;
  /* flex-basis: 100px; */
  display: flex;
  border-top: 1px solid #999;
  padding: 10px 30px; }
  .selection__col:first-child {
    flex-basis: 100%;
    border-top: none; }

.selection__title {
  flex-basis: 130px;
  text-align: left;
  padding-right: 10px;
  color: #C8453A; }

.selection__btn {
  position: relative;
  flex-basis: 100%;
  text-align: center;
  margin-top: 30px; }
  .selection__btn:before {
    content: '';
    position: absolute;
    width: 40%;
    height: 1px;
    background: #999;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }
  .selection__btn:after {
    content: '';
    position: absolute;
    width: 40%;
    height: 1px;
    background: #999;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
  .selection__btn .button {
    margin: auto; }

@media (max-width: 767.98px) {
  .selection__col {
    flex-basis: 100%;
    font-size: 15px; }
    .selection__col:nth-child(1) {
      order: 0; }
    .selection__col:nth-child(2) {
      order: 1; }
    .selection__col:nth-child(3) {
      order: 4; }
    .selection__col:nth-child(4) {
      order: 2; }
    .selection__col:nth-child(5) {
      order: 5; }
    .selection__col:nth-child(6) {
      order: 3; }
    .selection__col:nth-child(7) {
      order: 6; }
  .selection__btn {
    order: 7; } }
